<template>
  <div>
    <dialog-edit :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ id ? data.city + " " + data.address : "Новое для рассмотрения" }}
        <v-switch style="position: absolute; right: 62px; top: 0px" color="blue" v-if="ratingAccess" v-model="switchRating" label="Рейтинг" dense hide-details>
        </v-switch>
      </template>
      <v-layout px-2 py-2 mt-1 row wrap>
        <v-flex xs12 px-2 pb-3>
          <a-form-model
            v-model="data"
            :model="getFormModel(['source', 'name'])"
            :values="data"
            :errors="errors"
            :config="{ dense: true, rating, readonly, hideDetails: 'auto' }"
            @validate="validate($event)"
          />
        </v-flex>

        <v-flex sm12 px-2 mb-3 mt-0>
          <v-expansion-panels accordion v-model="panelPlan">
            <v-expansion-panel>
              <v-expansion-panel-header>Данные для планирования</v-expansion-panel-header>
              <v-expansion-panel-content>
                <a-form-modelCols
                  v-model="data"
                  class="pb-3"
                  :model="
                    getFormModel([
                      'credit_sum',
                      'data.investor_share',
                      'plan_sell_price',
                      'plan_expenses',
                      'credit_first_payment',
                      'plan_org_expenses',
                      'data.doc_buy_price',
                      'data.doc_sell_price',
                      'plan_month_expenses',
                      'data.donor_id',
                      'plan_investment_amount',
                      'data.is_procuratory',
                      'data.is_agreement_investor',
                      'data.is_agreement_donor',
                      'plan_date_finish',
                    ])
                  "
                  :values="data"
                  :errors="errors"
                  :config="{ dense: true, rating, readonly, hideDetails: 'auto' }"
                  @validate="validate($event)"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-flex>

        <a-form-modelCols
          v-model="data"
          class="pb-3 mx-1 pt-2"
          :model="
            getFormModel(
              'city#6,address#6,class#4,type#4,building_type#4,ap_area_real#3,ap_area_plan#3,layout_legal#6,ap_area#2,ap_kitchen_area#2,price_m2#2,ap_price_buy#6,ap_floor#2,building_floors#2,ap_number#2,ap_rooms#2,ap_ceiling_height#2,ap_level#2,ap_layout#6,ap_view#6,infrastructure,nearby,description,metro,data.vendor,photos,photos_design,doc,doc_adv,doc_law'.split(
                ','
              )
            )
          "
          :values="data"
          :errors="errors"
          :config="{ dense: true, rating, readonly, hideDetails: 'auto' }"
          @validate="validate($event)"
        />
      </v-layout>

      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save v-if="getAccess('consider.edit')" @click="submit()" class="pr-2"></a-btn-save>
        <a-btn-delete v-if="id && getAccess('consider.delete')" @click="removeDialogShow = true"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "../../components/mixings";

export default {
  mixins: [getAccess, getForm, submitForm, removeEl, genModel],
  props: {
    value: Boolean,
    id: Number,
    api: String,
    defaults: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      m: this.$store.getters["config/get"].models.objects,
      oModel: {},
      removeDialogShow: false,
      ratingAccess: this.getAccess("consider.rating"),
      switchRating: false,
      panelPlan: null,
    };
  },
  computed: {
    config() {
      return this.m?.config?.considers || {};
    },
    model() {
      //let config = JSON.parse(JSON.stringify(this.m?.config?.considers || {}));

      let model = this.calcModelConfig(this.config);
      return model;
    },
    rating() {
      return this.ratingAccess && this.switchRating;
    },
    name() {
      if (!this.data) return "";
      return this.data.city + " " + this.data.address;
    },
    vendor: {
      get() {
        let res = this.data.data?.vendor || "";
        return res;
      },
      set(v) {
        if (!this.data.data?.vendor) this.data.data = Object.assign({}, this.data.data, { vendor: "" });

        this.data.data.vendor = v;
      },
    },
    readonly() {
      if (!this.id) return false;
      return !this.getAccess("consider.edit", {
        id: this.id,
        access: this.data.object_access,
        data: this.data,
      });
    },
  },
  watch: {
    "data.ap_price_buy"() {
      this.calcPrice_m2();
    },
    "data.ap_area"() {
      this.calcPrice_m2();
    },

    value() {
      if (this.value) {
        this.updateData(this.id);
      }
    },
  },

  created() {
    this.makeObjectModel();
  },
  methods: {
    calcPrice_m2() {
      this.data.price_m2 = "";
      if (this.data.ap_area && this.data.ap_price_buy) {
        this.data.price_m2 = (this.data.ap_price_buy / this.data.ap_area).toFixed(2);
      }
    },
    onRating(e) {
      if (!this.data.data?.rating) this.data.data = Object.assign({}, this.data.data, { rating: {} });
      let rating = JSON.parse(JSON.stringify(this.data.data?.rating));

      rating[e.name] = e.value;
      this.data.data.rating = rating;
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
      this.$router.push({ name: "considers" });
    },
    async afterSave(data, status) {
      if (this.ratingAccess && data.rating !== null && 0 == 1) {
        let ratingOld;
        let dataOld = data.data || {};
        try {
          ratingOld = dataOld?.rating || {};
        } catch (error) {
          ratingOld = {};
        }
        if (ratingOld !== data.rating) {
          this.loading = true;
          dataOld.rating = this.data.rating;
          let data = { id: this.data.id, data: dataOld };
          let response = await this.$axios.post(this.api, data);
          this.loading = false;
          let status = response.data.status == "ok";
          if (status) {
            this.$root.$emit("show-info", {
              text: "Рейтинги обновлены",
            });
          }
        }
      }
      if (status) this.$emit("input");
    },
  },
};
</script>
